<template>
  <div class="scroll-container" :style="{maxHeight: `${height}${heightUnit}`}">
    <div class="scroll-pane">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {type: Number, default: 500},
    heightUnit: {type: String, default: 'px'},
  }
}
</script>

<style>
.scroll-container {
  overflow-y: auto;
}
.scroll-pane {
}
</style>